import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '~components/layouts/Default';
import PageHeader from '~components/common/PageHeader';
import Container from '~components/common/Container';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import SvgInstagram from '~components/common/svgs/SvgInstagram';
import SvgFacebook from '~components/common/svgs/SvgFacebook';
import SvgAnchorFm from '~components/common/svgs/SvgAnchorFm';
import P from '~components/typography/P';
import H2 from '~components/typography/H2';
import GridTwo from '~components/common/GridTwo';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="So nimmst du mit uns Kontakt auf, wenn du Fragen hast" desc="Du bist neugierig geworden? Sende uns eine Brieftaube oder schreib uns einfach eine Mail" active="Kontaktformular">
      <PageHeader title="Kontakt" breadcrumb={[{ title: 'Kontakt' }]} />
      <Container noMargin className="my-8">
        <div className="relative rounded-xl bg-white">
          <div className="grid grid-cols-1 lg:grid-cols-3">
            <div className="relative overflow-hidden bg-brand-green px-6 py-10 text-brand-gray sm:px-10 xl:p-12">
              <H2 className="mb-5 font-display">Kontaktinformationen</H2>
              <P className="mb-3">Du hast noch Fragen, Wünsche oder Ideen? Dann würden wir uns freuen wenn du uns direkt hier teilhaben lässt oder dich auf Facebook bei uns meldest.</P>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">Telefon</span>
                </dt>
                <dd className="flex text-base text-brand-gray">
                  <PhoneIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                  <span className="ml-3">
                    <a href={`tel:${data.site.siteMetadata.contact.phone}`} className="hover:underline">
                      {data.site.siteMetadata.contact.phone}
                    </a>
                  </span>
                </dd>
                <dt>
                  <span className="sr-only">Email</span>
                </dt>
                <dd className="flex text-base text-brand-gray">
                  <MailIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                  <span className="ml-3">
                    <a href={`mailto:${data.site.siteMetadata.contact.mail}`} className="hover:underline">
                      {data.site.siteMetadata.contact.mail}
                    </a>
                  </span>
                </dd>
              </dl>
              <ul className="mt-8 flex space-x-12">
                <li>
                  <a href={`${data.site.siteMetadata.social.instagram}`} target="_blank" rel="noreferrer" className="text-brand-gray hover:text-gray-100">
                    <SvgInstagram className="h-6" />
                  </a>
                </li>
                <li>
                  <a href={`${data.site.siteMetadata.social.facebook}`} target="_blank" rel="noreferrer" className="text-brand-gray hover:text-gray-100">
                    <SvgFacebook className="h-6" />
                  </a>
                </li>

                <li>
                  <a href={`${data.site.siteMetadata.social.anchor}`} target="_blank" rel="noreferrer" className="text-brand-gray hover:text-gray-100">
                    <SvgAnchorFm className="h-6" />
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact form */}
            <div className="px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 className="text-lg font-medium ">Schreibe uns</h3>
              <form name="contact" method="POST" data-netlify="true" action="/nachricht-gesendet" className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <input type="hidden" name="form-name" value="contact" />
                <div>
                  <label htmlFor="first_name" className="block text-sm font-medium ">
                    Name
                  </label>
                  <div className="mt-1">
                    <input type="text" name="Vorname" id="vorname" autoComplete="given-name" className="block w-full border border-black px-4 py-3 shadow-sm focus:border-brand focus:ring-brand" required />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium ">
                    E-Mail
                  </label>
                  <div className="mt-1">
                    <input id="email" name="E-Mail" type="email" autoComplete="email" className="block w-full border border-black px-4 py-3 shadow-sm focus:border-brand focus:ring-brand" required />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="nachricht" className="block text-sm font-medium ">
                      Nachricht
                    </label>
                  </div>
                  <div className="mt-1">
                    <textarea id="nachricht" name="nachricht" rows={4} className="block w-full border border-black px-4 py-3 shadow-sm focus:border-brand focus:ring-brand " aria-describedby="message-max" defaultValue={''} required />
                  </div>
                </div>
                {/* <div className="sm:col-span-2">
									<div className="flex justify-between">
										<label htmlFor="datei" className="block text-sm font-medium ">
											Datei (max. 5MB)
										</label>
									</div>
									<div className="mt-1">
										<input
											id="datei"
											name="datei"
											type="file"
											accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
											className="block w-full px-4 py-3 border border-black shadow-sm focus:ring-brand focus:border-brand"
										/>
									</div>
								</div> */}
                <div className="sm:col-span-2 sm:flex">
                  <P className="text-xs">
                    Wir respektieren Dein Vertrauen und behandeln Deine Daten mit Respekt. Mit dem Absenden dieses Formulars akzeptierst Du unsere{' '}
                    <Link to="/datenschutz/" className="font-medium underline">
                      Datenschutzerklärung
                    </Link>
                    .
                  </P>
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                  <button
                    type="submit"
                    className="focus:outline-none mt-2 inline-flex w-full items-center justify-center border border-transparent bg-brand-green px-6 py-3 text-base font-medium text-brand-gray shadow-sm hover:bg-brand-green-dark focus:ring-2 focus:ring-brand focus:ring-offset-2 sm:w-auto"
                  >
                    Senden
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <GridTwo className="my-8">
          <div>
            <P className="mb-3">
              Bestimmt kennst du den Leitspruch: Es gibt keine dummen Fragen, nur dumme Antworten. Die bekommst du bei uns selbstverständlich nicht. Denn wir nehmen dich mit deinen Bedürfnissen und Fragen ernst und möchten deine Fragen so
              gut es geht beantworten. Deshalb nutze doch gerne das untenstehende Kontaktformular und sende uns deine Frage.
            </P>
            <P className="mb-3">
              Nutze dafür das vorgefertigte Dokument und fülle alle freien Felder aus. Das Kontaktformular ist dafür gedacht, uns Anfragen oder deine Fragen zu zusenden, die sich entweder auf inhaltliche oder technische Fragen beziehen.
              Vielleicht hast du aber auch ein ganz anderes Anliegen, dann ist das Kontaktformular perfekt für dich. Je konkreter du uns dein Anliegen schilderst, desto besser können wir auf deine individuellen Bedürfnisse eingehen und
              desto besser können wir dir antworten.
            </P>
            <P className="mb-3">
              Viele der an uns gerichteten Fragen nehmen wir gerne in unsere{' '}
              <Link to="/faq/" className="underline">
                FAQ
              </Link>{' '}
              auf, dass du hier findest. Nach und nach erweitern wir den Fragenkatalog, sodass du es anderen Teilnehmenden mit deiner Frage vielleicht ein bisschen einfacher machst. Wenn deine Frage nicht dabei ist, schreib uns eine E-Mail
              oder nutze unseren telefonischen Terminservice, um direkt mit Johannes oder Fabian zu sprechen. Dafür kannst du hier einen Termin über{' '}
              <Link to="/termin/" className="underline">
                Calendly
              </Link>{' '}
              buchen. Sollte das bei dir nicht funktionieren oder dir zu kompliziert sein, nutze einfach wieder dieses Kontaktformular.
            </P>
          </div>
          <div>
            <P className="mb-3">
              Schreibe uns dann doch gerne auch in die E-Mail, wie und wann wir dich am besten erreichen und vor allem über welchen Kanal. Wir versuchen uns gerne darauf einzurichten und beantworten ggf. deine Frage dann auch persönlich am
              Telefon. Denk dran, jede Frage ist es uns wert beantwortet zu werden, trau dich daher einfach.
            </P>
            <P className="mb-3">
              Gerne kannst du dich auch mit uns auf den Social Media Kanälen vernetzen und dort fleißig kommentieren oder deine Fragen loswerden. Du findest uns auf Facebook, Instagram und auch auf YouTube! Die Links findest du links in der
              Spalte und kannst durch Draufklicken direkt weitergeleitet werden.
            </P>
            <P>
              Auch über Anfragen zu Podcastinterviews freuen wir uns! Du bist Podcast-Fan und hast Lust, in einem der Folgen dabei zu sein? Dann schreib uns eine Mail mit deiner Idee. Wir sind auf der Suche nach Interviewpartner*innen zu
              den Themen Glück, Achtsamkeit, Entspannung und Lifestyle. Wenn du also etwas zu erzählen hast oder der Welt etwas geben möchtest, dann melde dich bei uns.
            </P>
            <P>
              Anfragen zur betrieblichen Gesundheitsförderung sind stets willkommen. Vielleicht bist du auch in der Rolle der Personalverantwortung und bist auf der Suche nach Anbietern für Gesundheitskurse, Workshops und Seminare. Auch
              dann laden wir dich ein, uns zu kontaktieren. Denn als zertifizierter Gesundheitsdienstleister unterstützen wir verschiedenste Unternehmen bei der Förderung der Gesundheit. Also schreib uns!
            </P>
          </div>
        </GridTwo>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query {
    allFaq {
      nodes {
        text
        frage
        id
      }
    }
    site {
      siteMetadata {
        contact {
          mail
          phone
        }
        social {
          instagram
          facebook
          anchor
        }
      }
    }
  }
`;
